import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllPortfolios = 'api/portfolios',
  GetPortfolioById = 'api/portfolios/getById',
  CreatePortfolio = 'api/portfolios/create',
  UpdatePortfolio = 'api/portfolios/update',
  DeletePortfolio = 'api/portfolios/delete',
  GetPortfolioTranslation = 'api/portfolios/getTranslation',
  UpdatePortfolioTranslation = 'api/portfolios/updateTranslation',
  GetPortfolioGallery = 'api/portfolios/getGallery',
  AddPortfolioGalleryImage = 'api/portfolios/addGalleryImage',
  RemovePortfolioGalleryImage = 'api/portfolios/removeGalleryImage',
  UpdatePortfolioLogo = 'api/portfolios/updateLogo',
  UpdatePortfolioPreview = 'api/portfolios/updatePreviewImage',
}


export type API_Portfolio = {
  id: number
  parentId?: number | null
  keyword?: string
  published?: boolean | null
  pageId?: number
  translation?: API_PortfolioTranslation[]
  taxonomies?: API_PortfolioTaxonomy[]
  logo?: {
    id: number
    type: string
    folder: number
    filename: string
    basepath: string
    path: string
  } | null
  previewImage?: {
    id: number
    type: string
    folder: number
    filename: string
    basepath: string
    path: string
  } | null
  mainImage?: {
    id: number
    type: string
    folder: number
    filename: string
    basepath: string
    path: string
  }
}

export type API_PortfolioTranslation = {
  id?: number
  name?: string
  description?: string
  shortDescription?: string
  slug?: string | null
}

export type API_PortfolioTaxonomy = {
  id: number
  name: string
  slug: string
}




//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetPortfoliosRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}

export interface GetPortfoliosResponse extends APIPaginatableResponse {
  portfolios: API_Portfolio[]
}

enum GetPortfoliosResponseError {
}

export async function getPortfolios(params: GetPortfoliosRequestParams, output?: RequestOutput<GetPortfoliosResponse, GetPortfoliosResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPortfolios,
    params: params,
    output: output,
  })
}




export type GetPortfolioRequestParams = {
  id: number
}
export type GetPortfolioResponse = {
  portfolio: API_Portfolio
}

enum GetPortfolioResponseError {
}

export async function getPortfolio(params: GetPortfolioRequestParams, output?: RequestOutput<GetPortfolioResponse, GetPortfolioResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPortfolioById,
    params: params,
    output: output,
  })
}



export type CreatePortfolioRequestParams = {
  parentId: number | null
}

type CreatePortfolioResponse = {
  portfolio: {
    id: number
    name: string
  }
}

enum CreatePortfolioReponseError {
}

export async function createPortfolio(params: CreatePortfolioRequestParams, output?: RequestOutput<CreatePortfolioResponse, CreatePortfolioReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreatePortfolio,
    params: params,
    output: output,
  })
}



export type UpdatePortfolioRequestParams = API_Portfolio

type UpdatePortfolioResponse = {
}

enum UpdatePortfolioReponseError {
}

export async function updatePortfolio(params: UpdatePortfolioRequestParams, output?: RequestOutput<UpdatePortfolioResponse, UpdatePortfolioReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePortfolio,
    params: params,
    output: output,
  })
}



export type DeletePortfolioRequestParams = {
  id: number
}

type DeletePortfolioResponse = {
}

enum DeletePortfolioReponseError {
}

export async function deletePortfolio(params: DeletePortfolioRequestParams, output?: RequestOutput<DeletePortfolioResponse, DeletePortfolioReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeletePortfolio,
    params: params,
    output: output,
  })
}




export type GetPortfolioTranslationRequestParams = {
  portfolioId: number
  languageId: number
}

export type GetPortfolioTranslationResponse = {
  portfolioTranslation: API_PortfolioTranslation
}

enum GetPortfolioTranslationResponseError {
}

export async function getPortfolioTranslation(params: GetPortfolioTranslationRequestParams, output?: RequestOutput<GetPortfolioTranslationResponse, GetPortfolioTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPortfolioTranslation,
    params: params,
    output: output,
  })
}



export type UpdatePortfolioTranslationRequestParams = API_PortfolioTranslation

type UpdatePortfolioTranslationResponse = {
}

enum UpdatePortfolioTranslationReponseError {
}

export async function updatePortfolioTranslation(params: UpdatePortfolioTranslationRequestParams, output?: RequestOutput<UpdatePortfolioTranslationResponse, UpdatePortfolioTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePortfolioTranslation,
    params: params,
    output: output,
  })
}



export interface GetPortfolioGalleryRequestParams {
  portfolioId: number
}

export interface GetPortfolioGalleryResponse {
  portfolioGallery: {
    id: number
    itemOrder: number
    image: {
      id: number
      filename: string,
      type: string,
      basepath: string
      folder: number
    }
  }[]
}

enum GetPortfolioGalleryResponseError {
}

export async function getPortfolioGallery(params: GetPortfolioGalleryRequestParams, output?: RequestOutput<GetPortfolioGalleryResponse, GetPortfolioGalleryResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPortfolioGallery,
    params: params,
    output: output,
  })
}



export interface AddPortfolioGalleryImageRequestParams {
  portfolioId: number
  mediaFileId: number
}

export interface AddPortfolioGalleryImageResponse {
}

enum AddPortfolioGalleryImageResponseError {
}

export async function addPortfolioGalleryImage(params: AddPortfolioGalleryImageRequestParams, output?: RequestOutput<AddPortfolioGalleryImageResponse, AddPortfolioGalleryImageResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddPortfolioGalleryImage,
    params: params,
    output: output,
  })
}



export interface RemovePortfolioGalleryImageRequestParams {
  id: number
}

export interface RemovePortfolioGalleryImageResponse {
}

enum RemovePortfolioGalleryImageResponseError {
}

export async function removePortfolioGalleryImage(params: RemovePortfolioGalleryImageRequestParams, output?: RequestOutput<RemovePortfolioGalleryImageResponse, RemovePortfolioGalleryImageResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemovePortfolioGalleryImage,
    params: params,
    output: output,
  })
}



export type UpdatePortfolioLogoRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdatePortfolioLogoResponse = {
}

enum UpdatePortfolioLogoReponseError {
}

export async function updatePortfolioLogo(params: UpdatePortfolioLogoRequestParams, output?: RequestOutput<UpdatePortfolioLogoResponse, UpdatePortfolioLogoReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePortfolioLogo,
    params: params,
    output: output,
  })
}

export type UpdatePortfolioPreviewRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdatePortfolioPreviewResponse = {
}

enum UpdatePortfolioPreviewReponseError {
}

export async function updatePortfolioPreview(params: UpdatePortfolioPreviewRequestParams, output?: RequestOutput<UpdatePortfolioPreviewResponse, UpdatePortfolioPreviewReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePortfolioPreview,
    params: params,
    output: output,
  })
}
