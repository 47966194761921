import React from 'react'
import { Page, PageProps, Dialog } from 'components'
import { useLocalization } from 'components/methods'
import { makeStyles, MenuItem, ListItemIcon } from '@material-ui/core';
import { userIs } from 'app/entities/methods'
import { UserRoleValue } from 'app/values'
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import _ from 'lodash'
import CancelIcon from '@material-ui/icons/Cancel'
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: Color.Error
  },
}))


export type PageEntityDetailsRefreshableProps = {
  refreshObject?: () => void
}

interface PageEntityDetailsProps extends PageProps {
  deleteFunction?: () => void
}

const PageEntityDetails = ({ ...props }: PageEntityDetailsProps) => {
  const session = useSelector((state: RootState) => state.session)
  const classes = useStyles()
  const { t } = useLocalization()



  //ACTIONS

  function getSecondaryActions(): React.ReactNode[] | undefined {
    let actions = _.clone(props.secondaryActions)
    if (actions == null) actions = []
    if (props.deleteFunction != null && userIs(session.user, UserRoleValue.Dev) === true) {
      actions?.push(<MenuItem onClick={handleDeleteDialogOpen}><ListItemIcon><CancelIcon className={classes.deleteIcon} /></ListItemIcon>{t('component.PageEntityDetails.action.delete')}</MenuItem>)
    }
    return actions
  }



  //DELETE
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  function deleteEntity() {
    if (props.deleteFunction != null) props.deleteFunction()
  }


  //RENDER

  return (
    <>
      <Page {...props}
        tabs={props.tabs} defaultTab={props.defaultTab} selectedTab={props.selectedTab} updateTab={props.updateTab}
        language={props.language} updateLanguage={props.updateLanguage}
        secondaryActions={getSecondaryActions()}
      >
        {props.children}
      </Page>

      <Dialog open={deleteDialogOpen}
        title={t('component.PageEntityDetails.delete-dialog.title')}
        description={t('component.PageEntityDetails.delete-dialog.description')}
        confirmText={t('component.PageEntityDetails.delete-dialog.confirm')}
        confirmButtonCategory={'delete'}
        onConfirm={deleteEntity}
        onClose={handleDeleteDialogClose}
      />
    </>
  )
}

export default PageEntityDetails