import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import UsersIcon from '@material-ui/icons/PeopleAlt'
import CodeIcon from '@material-ui/icons/Code'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import SettingsIcon from '@material-ui/icons/Settings'
import AdbIcon from '@material-ui/icons/Adb'
import PanoramaIcon from '@material-ui/icons/Panorama'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import FaceIcon from '@material-ui/icons/Face'
import FavoriteIcon from '@material-ui/icons/Favorite'
import PaymentIcon from '@material-ui/icons/Payment'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'

const items = [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pages', icon: PageIcon, href: '/website/pages' },
      { title: 'sidebar.item.dictionary', icon: CommonTextIcon, href: '/website/dictionary' },
      { title: 'sidebar.item.media-library', icon: PhotoLibraryIcon, href: '/website/media-library', },
      { title: 'sidebar.item.team-members', icon: AccessibilityNewIcon, href: '/website/team-members', },
      { title: 'sidebar.item.portfolio', icon: ArtTrackIcon, href: '/website/portfolio', },
    ]
  },

  {
    title: 'sidebar.title.blog', pages: [
      { title: 'sidebar.item.blog', icon: ImportContactsIcon, href: '/website/blog', },
    ]
  },

  {
    title: 'sidebar.title.shop', pages: [
      { title: 'sidebar.item.products', icon: PanoramaIcon, href: '/shop/items/', },
      { title: 'sidebar.item.wishlists', icon: FavoriteIcon, href: '/shop/wishlists', },
      { title: 'sidebar.item.carts', icon: LocalMallIcon, href: '/shop/carts', },
      { title: 'sidebar.item.orders', icon: PaymentIcon, href: '/shop/orders', },
      { title: 'sidebar.item.customers', icon: FaceIcon, href: '/shop/customers', },
    ]
  },

  {
    title: 'sidebar.title.admin', pages: [
      { title: 'sidebar.item.users', icon: UsersIcon, href: '/admin/users' },
      { title: 'sidebar.item.automation', icon: AdbIcon, href: '/admin/automation' },
      { title: 'sidebar.item.settings', icon: SettingsIcon, href: '/admin/settings' },
      { title: 'sidebar.item.dev', icon: CodeIcon, href: '/dev/overview' },
    ]
  },
]

export default items