import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllTeamMembers = 'api/teamMembers',
  GetTeamMemberById = 'api/teamMembers/getById',
  CreateTeamMember = 'api/teamMembers/create',
  UpdateTeamMember = 'api/teamMembers/update',
  DeleteTeamMember = 'api/teamMembers/delete',
  GetTeamMemberTranslation = 'api/teamMembers/getTranslation',
  UpdateTeamMemberTranslation = 'api/teamMembers/updateTranslation',
}


export type API_TeamMember = {
  id: number
  parentId?: number | null
  keyword?: string
  published?: boolean | null
  pageId?: number
  year?: number | null
  instagram?: string | null
  linkedIn?: string | null
  translation?: API_TeamMemberTranslation[]
  mainImage?: {
    id: number
    type: string
    folder: number
    filename: string
    basepath: string
    path: string
  }
}

export type API_TeamMemberTranslation = {
  id?: number
  name?: string
  bio?: string
  shortDescription?: string
  quote?: string
  slug?: string | null
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetTeamMembersRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}

export interface GetTeamMembersResponse extends APIPaginatableResponse {
  teamMembers: API_TeamMember[]
}

enum GetTeamMembersResponseError {
}

export async function getTeamMembers(params: GetTeamMembersRequestParams, output?: RequestOutput<GetTeamMembersResponse, GetTeamMembersResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllTeamMembers,
    params: params,
    output: output,
  })
}




export type GetTeamMemberRequestParams = {
  id: number
}
export type GetTeamMemberResponse = {
  teamMember: API_TeamMember
}

enum GetTeamMemberResponseError {
}

export async function getTeamMember(params: GetTeamMemberRequestParams, output?: RequestOutput<GetTeamMemberResponse, GetTeamMemberResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTeamMemberById,
    params: params,
    output: output,
  })
}



export type CreateTeamMemberRequestParams = {
  parentId: number | null
}

type CreateTeamMemberResponse = {
  teamMember: {
    id: number
    name: string
  }
}

enum CreateTeamMemberReponseError {
}

export async function createTeamMember(params: CreateTeamMemberRequestParams, output?: RequestOutput<CreateTeamMemberResponse, CreateTeamMemberReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateTeamMember,
    params: params,
    output: output,
  })
}



export type UpdateTeamMemberRequestParams = API_TeamMember

type UpdateTeamMemberResponse = {
}

enum UpdateTeamMemberReponseError {
}

export async function updateTeamMember(params: UpdateTeamMemberRequestParams, output?: RequestOutput<UpdateTeamMemberResponse, UpdateTeamMemberReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTeamMember,
    params: params,
    output: output,
  })
}



export type DeleteTeamMemberRequestParams = {
  id: number
}

type DeleteTeamMemberResponse = {
}

enum DeleteTeamMemberReponseError {
}

export async function deleteTeamMember(params: DeleteTeamMemberRequestParams, output?: RequestOutput<DeleteTeamMemberResponse, DeleteTeamMemberReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteTeamMember,
    params: params,
    output: output,
  })
}




export type GetTeamMemberTranslationRequestParams = {
  teamMemberId: number
  languageId: number
}

export type GetTeamMemberTranslationResponse = {
  teamMemberTranslation: API_TeamMemberTranslation
}

enum GetTeamMemberTranslationResponseError {
}

export async function getTeamMemberTranslation(params: GetTeamMemberTranslationRequestParams, output?: RequestOutput<GetTeamMemberTranslationResponse, GetTeamMemberTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTeamMemberTranslation,
    params: params,
    output: output,
  })
}



export type UpdateTeamMemberTranslationRequestParams = API_TeamMemberTranslation

type UpdateTeamMemberTranslationResponse = {
}

enum UpdateTeamMemberTranslationReponseError {
}

export async function updateTeamMemberTranslation(params: UpdateTeamMemberTranslationRequestParams, output?: RequestOutput<UpdateTeamMemberTranslationResponse, UpdateTeamMemberTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTeamMemberTranslation,
    params: params,
    output: output,
  })
}
