import React, { useState, useEffect } from 'react'
import { CardListing, PageEntityDetailsRefreshableProps, ListHeaderProps, ListRowProps, ListSorting } from 'components'
import { useLocalization } from 'components/methods'
import { Order, OrderItem } from 'app/entities/types'

interface CardShopOrderItemsProps extends PageEntityDetailsRefreshableProps {
	object: Order,
}

const CardShopOrderItems = ({ ...props }: CardShopOrderItemsProps) => {
	const { t } = useLocalization()

	const [isLoading, setIsLoading] = useState(false)
	const [appointments] = useState<OrderItem[] | null>(props.object.items ?? null)

	useEffect(() => {
		const initialized = props.object != null
		setIsLoading(!initialized)
	}, [props.object, appointments])



	//APPOINTMENTS DATA

	const sorting: ListSorting = { order: 'id', direction: 'asc' }

	const headers: ListHeaderProps = [
		{ key: 'id', sortable: true },
		{ key: 'product', name: t('component.CardShopOrderItems.list.column.product'), flexible: true },
		{ key: 'quantity', name: t('component.CardShopOrderItems.list.column.quantity') },
		{ key: 'unitAmount', name: t('component.CardShopOrderItems.list.column.unit-price') },
		{ key: 'unitTaxAmount', name: t('component.CardShopOrderItems.list.column.unit-tax') },
		{ key: 'amount', name: t('component.CardShopOrderItems.list.column.total-amount') },
	]

	const createRow = (item: OrderItem): ListRowProps => {
		return {
			data: [
				{ key: 'id', value: item.id },
				{ key: 'product', value: printProduct(item) },
				{ key: 'quantity', value: printQuantity(item) },
				{ key: 'unitAmount', value: printUnitAmount(item) },
				{ key: 'unitTaxAmount', value: printUnitTaxAmount(item) },
				{ key: 'amount', value: printTotalAmount(item) },
			],
		}

		function printProduct(item: OrderItem): string {
			if (item.product == null) return ''
			return item.product.name
		}
		function printQuantity(item: OrderItem): number {
			return item.quantity
		}
		function printUnitAmount(item: OrderItem): string {
			return item.currency.htmlSymbol + ' ' + String(item.unitTaxableAmount)
		}
		function printUnitTaxAmount(item: OrderItem): string {
			return item.currency.htmlSymbol + ' ' + String(item.unitTaxAmount)
		}
		function printTotalAmount(item: OrderItem): string {
			return item.currency.htmlSymbol + ' ' + String(item.totalAmount)
		}
	}




	//RENDER

	return (
		<>
			<CardListing
				enableSearch={false}
				enableFooter={false}
				id={'order-items'}
				title={t('component.CardShopOrderItems.title')}
				isLoading={isLoading}
				items={appointments}
				headers={headers}
				dataConstructor={createRow}
				sorting={sorting}
			/>
		</>
	)
}

export default CardShopOrderItems