import { Order } from 'app/entities/types'
import { OrderStatusValue } from 'app/values'

export function orderStatusIsCart(u: Order): boolean {
  return u.status.keyword === OrderStatusValue.Cart
}

export function getOrderShippingAddress(o: Order): string {
  if (o.shippingAddress == null) return '–'
  return o.shippingAddress.address1 + ', ' + o.shippingAddress.city
}
