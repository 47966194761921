import { lazy } from 'react'
import { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
	{ path: '/website/team-members', exact: true, component: lazy(() => import('views/screens/website/TeamMembers')) },
	{ path: '/website/team-members/team-member/:id/:tab?', exact: true, component: lazy(() => import('views/screens/website/TeamMemberDetails')) },

	{ path: '/website/portfolio/:tab?', exact: true, component: lazy(() => import('views/screens/website/Portfolio')), },
	{ path: '/website/portfolio/items/:id/:tab?', exact: true, component: lazy(() => import('views/screens/website/PortfolioDetails')) },
	{ path: '/website/portfolio/category/:id/:tab?', exact: true, component: lazy(() => import('views/screens/website/PortfolioCategoryDetails')) },
]

export default routes